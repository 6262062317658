import {
  faBrowser,
  faChevronRight,
  faCopyright,
  faEdit,
  faTrashAlt,
  faUserSlash,
  faVolumeMute,
  faFileDownload,
} from "@fortawesome/pro-light-svg-icons"
import { faEllipsisH, faInfoCircle } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Fade } from "@material-ui/core"
import Popper from "@material-ui/core/Popper"
import {
  bindHover,
  bindPopper,
  bindToggle,
  usePopupState,
} from "material-ui-popup-state/hooks"
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react"
import { useIndexedDB } from "../hooks/use-indexeddb"
import { useModal } from "../hooks/use-modal"
import { useStyle } from "../hooks/use-style"
import { getCachedSecret, useUser } from "../hooks/use-user"
import { convertChannel } from "../services/content"
import { GET_channel } from "../services/http"
import { rpc } from "../services/node"
import { darkTheme, lightTheme } from "../services/theme"
import { Channel, Item, LikeType, MuteType } from "../types"
import ChannelEditor from "./channel/editor"
import ClaimChannel from "./dialogs/claim-channel"
import ItemEditor from "./item/editor"
import Moderation from "./moderation"
import { showToast } from "./status-notification"
import ClickAwayListener from "react-click-away-listener"

type Props = {
  item: Item | null
  channel: Channel | null
  onUpdate: (updatedItem: Item) => void
  onMute: (id: string, kind: MuteType) => void
}

const DropdownCompactMenu: FunctionComponent<Props> = ({
  item,
  channel,
  onUpdate,
  onMute,
}) => {
  const { user, accountState, updateBalance } = useUser()
  const { setContent } = useModal()
  const { addKV } = useIndexedDB()

  const [] = useState(false)
  const [] = useState(false)

  const showEdit = useMemo(() => {
    //console.log("-- " + JSON.stringify(item) + " " + JSON.stringify(channel))
    //console.log("-- " + user.state + " " + channel.id)
    if (user) {
      if (item && (user.state >= 10 || (item.owner && item.owner == user.id)))
        return true
      else if (
        channel &&
        (user.state >= 10 || (channel.owner && channel.owner == user.id)) &&
        (channel.id.indexOf(".") != -1 || channel.id.indexOf("-") == -1)
      )
        return true
    }
    return false
    /*  
    if (
      channel == null ||
      channel.id.indexOf(".") != -1 ||
      channel.id.indexOf("-") == -1
    )
      return true
    else return false*/
  }, [channel, item, user])

  const showEditAccount = useMemo(() => {
    //console.log("-- " + JSON.stringify(item) + " " + JSON.stringify(channel))
    //console.log("-- " + user.state + " " + channel.id)
    if ((item && item.creator) || channel) return true
    else return false
  }, [channel, item, user])

  const optionsPopupState = usePopupState({
    variant: "popper",
    popupId: "optionsPopper",
  })
  const mutePopupState = usePopupState({
    variant: "popper",
    popupId: "mutePopper",
  })
  const markPopupState = usePopupState({
    variant: "popper",
    popupId: "markPopper",
  })
  const editPopupState = usePopupState({
    variant: "popper",
    popupId: "editPopper",
  })

  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.secondary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    infoColor:
      theme === "light"
        ? lightTheme.color.text.secondary
        : darkTheme.color.text.secondary,
    tagColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
  }))

  const deactivate = useCallback(() => {
    //console.log("***** deactivate")
    optionsPopupState.close()
  }, [optionsPopupState])

  /*
  const clickBoundary = useRef<HTMLDivElement>(null)
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        clickBoundary.current &&
        !clickBoundary.current.contains(event.target as Node)
      ) {
        console.log("click outside")
        //window.requestAnimationFrame(() => deactivate())
      }
    },
    [deactivate]
  )

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true)
    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, [handleClickOutside])
*/
  const onUpdateChannel = useCallback(() => {}, [channel])

  const showEditor = useCallback(() => {
    if (item) {
      setContent(
        <ItemEditor
          item={item}
          onUpdate={onUpdate}
          onChange={() => setContent(null)}
        />
      )
    } else if (channel) {
      setContent(
        <ChannelEditor
          channel={channel}
          onUpdate={onUpdateChannel}
          onChange={() => setContent(null)}
        />
      )
    }
    deactivate()
  }, [item, channel, deactivate])

  const editAccount = useCallback(() => {
    console.log("edit account")
    if (item && item.creator) {
      GET_channel(item.creator.id).then(res => {
        let ch = convertChannel(res)
        console.log(ch)
        if (ch)
          setContent(
            <ClaimChannel channel={ch} close={() => setContent(null)} />
            /*
            <ClaimAlias
              open={true}
              onClose={() => setContent(null)}
              close={() => setContent(null)}
              channel={ch}
              claim={claimOwnership}
            />*/
          )
      })
    } else if (channel) {
      setContent(
        <ClaimChannel channel={channel} close={() => setContent(null)} />
      )
      /*
      if (channel.alias_host) {
        setContent(
          <ClaimAlias
            open={true}
            onClose={() => setContent(null)}
            close={() => setContent(null)}
            channel={channel}
            claim={claimOwnership}
          />
        )
      } else {
        setContent(
          <ClaimDomain
            open={true}
            onClose={() => setContent(null)}
            close={() => setContent(null)}
            channel={channel}
            claim={claimOwnership}
          />
        )
      }*/
    }
    deactivate()
  }, [item, channel, deactivate])

  const reparse = useCallback(() => {
    if (item) {
      rpc("reparse", item.id, user, getCachedSecret(), function (res) {
        let message
        if (res.status == "ok") {
          message = "Reparse initiated"
          console.log(res.data.balance)
        } else message = "Reparse failed"

        showToast(faInfoCircle, "Smartlike network", message)
      })
    }
    deactivate()
  }, [item, deactivate])

  const deleteItem = useCallback(() => {
    if (item) {
      let payload = {
        id: item.id,
      }

      rpc(
        "delete_content",
        item.id,
        /*JSON.stringify(payload),*/
        user,
        getCachedSecret(),
        function (res) {
          //let amount = 1
          let message
          if (res.status == "ok") {
            message = "Delete supplied"
            console.log(res.data.balance)
            //onUpdate(editedChannel)
          } else message = "Delete failed"

          showToast(faInfoCircle, "Smartlike network", message)
        }
      )
    }
    deactivate()
  }, [item, deactivate])

  const mute = useCallback(
    (author: string, meta: any) => {
      let r = addKV("muted", author, {
        meta: meta,
        ts: Math.floor(Date.now() / 1000),
      })
      if (r) {
        r.onsuccess = function () {
          console.log("Added")
        }
        r.onerror = function () {
          console.log("error")
        }
      }
      deactivate()
    },
    [deactivate]
  )

  const muteAuthor = useCallback(() => {
    let channel_id = ""
    if (item && item.creator && item.creator.id && item.creator.id.length > 0) {
      channel_id = item.creator.id
    } else if (channel) {
      channel_id = channel.id
    }

    if (channel_id.length) {
      GET_channel(channel_id).then(res => {
        let ch = convertChannel(res)
        console.log(ch)
        mute(channel_id, ch)
        onMute(channel_id, MuteType.Creator)
      })
    }
    deactivate()
  }, [item, deactivate])

  const muteAuthorEverywhere = useCallback(() => {
    if (
      item &&
      item.creator &&
      item.creator.owner &&
      item.creator.owner.length > 0
    )
      mute(item.creator.owner, item.creator)
    else muteAuthor()
    deactivate()
  }, [item, deactivate])

  const muteSite = useCallback(() => {
    let channel_id = ""
    if (
      item &&
      item.publisher &&
      item.publisher.id &&
      item.publisher.id.length > 0
    ) {
      channel_id = item.publisher.id
    } else if (channel) {
      channel_id = channel.id
    }
    if (channel_id.length) {
      GET_channel(channel_id).then(res => {
        let ch = convertChannel(res)
        console.log(ch)
        mute(channel_id, ch)
        onMute(channel_id, MuteType.Domain)
      })
    }
    deactivate()
  }, [item, deactivate])

  const ref = useRef(null)

  const [] = useState(false)
  const [] = useState({ x: 0, y: 0 })

  const [] = useState(false)

  const [] = useState(false)

  const claimOwnership = useCallback(
    async (claim: Claim) => {
      rpc("claim", JSON.stringify(claim), user, getCachedSecret(), function (
        res
      ) {
        let amount = 1
        let message
        if (res.status == "ok") message = "Claim sent"
        else message = "Claim failed"

        if (user) updateBalance(-amount)
        showToast(faInfoCircle, "Smartlike network", message)
      })
    },
    [user]
  )

  const onClickAway = useCallback(() => {
    console.log("click away")
    deactivate()
  }, [deactivate])

  return (
    <ClickAwayListener onClickAway={deactivate}>
      <div className="ellipsis">
        <button ref={ref} {...bindToggle(optionsPopupState)}>
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>

        <Popper
          {...bindPopper(optionsPopupState)}
          transition
          placement="bottom"
          style={{ marginBottom: "0px", zIndex: 10000 }}
        >
          {({}) => (
            <div className="panel" style={{ width: "150px" }}>
              <ul>
                <li>
                  <div className="submenuItem" {...bindHover(mutePopupState)}>
                    <span className="menuLabel">Mute</span>
                    <div className="">
                      <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                  </div>

                  <Popper
                    {...bindPopper(mutePopupState)}
                    transition
                    placement="right"
                    style={{ marginBottom: "0px", zIndex: 1000 }}
                  >
                    {({ TransitionProps }) => (
                      <Fade {...TransitionProps} timeout={150}>
                        <div className="panel">
                          <ul>
                            {(item != null || channel.kind == 2) && (
                              <>
                                <li>
                                  <div
                                    className="menuItem"
                                    onClick={muteAuthor}
                                  >
                                    <div className="badge">
                                      <FontAwesomeIcon icon={faVolumeMute} />
                                    </div>
                                    <span className="menuLabel">
                                      Author on this site
                                    </span>
                                  </div>
                                </li>
                                <li>
                                  <div
                                    className="menuItem"
                                    onClick={muteAuthorEverywhere}
                                  >
                                    <div className="badge">
                                      <FontAwesomeIcon icon={faUserSlash} />
                                    </div>
                                    <span className="menuLabel">
                                      Author everywhere
                                    </span>
                                  </div>
                                </li>
                              </>
                            )}
                            <li>
                              <div className="menuItem" onClick={muteSite}>
                                <div className="badge">
                                  <FontAwesomeIcon
                                    className="badge"
                                    icon={faBrowser}
                                  />
                                </div>
                                <span className="menuLabel">Website</span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </Fade>
                    )}
                  </Popper>
                </li>
                {accountState > 0 && (
                  <>
                    <li>
                      <div
                        className="submenuItem"
                        {...bindHover(markPopupState)}
                      >
                        <span className="menuLabel">Mark as</span>
                        <div className="">
                          <FontAwesomeIcon icon={faChevronRight} />
                        </div>
                      </div>

                      <Popper
                        {...bindPopper(markPopupState)}
                        transition
                        placement="right"
                        style={{ marginBottom: "0px", zIndex: 1000 }}
                      >
                        {({ TransitionProps }) => (
                          <Fade {...TransitionProps} timeout={150}>
                            <Moderation
                              id={item ? item.id : channel.id}
                              contentType={
                                item ? LikeType.Item : LikeType.Channel
                              }
                              recipient={
                                item ? item.publisher.url : channel?.title
                              }
                              borderColor={css.borderColor}
                            />
                          </Fade>
                        )}
                      </Popper>
                    </li>

                    <li>
                      <div
                        className="submenuItem"
                        {...bindHover(editPopupState)}
                      >
                        <span className="menuLabel">Edit</span>
                        <div className="">
                          <FontAwesomeIcon icon={faChevronRight} />
                        </div>
                      </div>

                      <Popper
                        {...bindPopper(editPopupState)}
                        transition
                        placement="right"
                        style={{ marginBottom: "0px", zIndex: 1000 }}
                      >
                        {({ TransitionProps }) => (
                          <Fade {...TransitionProps} timeout={150}>
                            <div className="panel">
                              <ul>
                                {showEditAccount && (
                                  <li>
                                    <div
                                      className="menuItem"
                                      onClick={editAccount}
                                    >
                                      <div className="badge">
                                        <FontAwesomeIcon
                                          className="badge"
                                          icon={faCopyright}
                                        />
                                      </div>
                                      <span className="menuLabel">Account</span>
                                    </div>
                                  </li>
                                )}

                                {showEdit && (
                                  <>
                                    <li>
                                      <div
                                        className="menuItem"
                                        onClick={showEditor}
                                      >
                                        <div className="badge">
                                          <FontAwesomeIcon
                                            className="badge"
                                            icon={faEdit}
                                          />
                                        </div>
                                        <span className="menuLabel">
                                          Metadata
                                        </span>
                                      </div>
                                    </li>
                                    <li>
                                      <div
                                        className="menuItem"
                                        onClick={reparse}
                                      >
                                        <div className="badge">
                                          <FontAwesomeIcon
                                            className="badge"
                                            icon={faFileDownload}
                                          />
                                        </div>
                                        <span className="menuLabel">
                                          Reparse
                                        </span>
                                      </div>
                                    </li>
                                    <li>
                                      <div
                                        className="menuItem"
                                        onClick={deleteItem}
                                      >
                                        <div className="badge">
                                          <FontAwesomeIcon
                                            className="badge"
                                            icon={faTrashAlt}
                                          />
                                        </div>
                                        <span className="menuLabel">
                                          Delete
                                        </span>
                                      </div>
                                    </li>
                                  </>
                                )}
                              </ul>
                            </div>
                          </Fade>
                        )}
                      </Popper>
                    </li>
                  </>
                )}
              </ul>
            </div>
          )}
        </Popper>
        <style jsx>{`
          .ellipsis {
            color: rgb(129, 129, 129);
            z-index: 2
          }
          .ellipsis:hover {
            color: blue;
          }
          .panel {
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),0 6px 20px 0 rgba(0, 0, 0, 0.19);
            border-radius: 5px;
            background-color: white;
            padding-top: 5px;
            padding-bottom: 5px;
            z-index: 1000;

          }
          .menuLabel {
            display: table-cell;
            vertical-align: middle;
          }
          .badge {
            margin-right: 10px;
            font-size: 18px;
            color: rgb(96,96,96);
            width: 25px;
          }
          .menuItem {
              padding: 3px 20px;
              display: table;
          }
          .submenuItem {
              padding: 10px 20px;
              display: flex;
              justify-content: space-between;
              width: 100%;                 
          }

          ul {
            margin: 0;
            padding: 0;
            font-size: 14px;
            font-family: "Roboto", sans-serif;
          }
          li {
            display: flex;
          }
          li:hover {
            background-color: ${css.borderColor};
            cursor: pointer;
          }

          button {
              border-radius: 50%;
              height: 2em;
              width: 2em;
              display: flex;
              justify-content: center;
              align-items: center;
              border: none;
              background-color: transparent;
              color: inherit;
              transition: 200ms background-color;
              z-index: 1;
          }
          button:disabled {
              opacity: 0.5;
          }
          button:not(:disabled):hover {
              background-color: ${css.backgroundColor};
          }
          }
    `}</style>
      </div>
    </ClickAwayListener>
  )
}

export default DropdownCompactMenu
