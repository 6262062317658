import {
  faComment,
  faCommentDollar,
  faThumbsUp,
} from "@fortawesome/pro-light-svg-icons"
import { faThumbsUp as faThumbsUpActive } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Fade from "@material-ui/core/Fade"
import Popper from "@material-ui/core/Popper"
import {
  bindHover,
  bindPopper,
  usePopupState,
} from "material-ui-popup-state/hooks"
import React, { FunctionComponent, useCallback, useMemo, useRef } from "react"
import { useAnimatedCallback } from "../hooks/use-animated-callback"
import { useUser } from "../hooks/use-user"
import { prettyDate } from "../services/date"
import { prettyAmount } from "../services/number"
import { Item, LikeType } from "../types"
import LikeBar from "./like-bar"
import Flags from "./widgets/flags"
import { useLike } from "../hooks/use-like"
import HtmlTooltip from "./widgets/html-tooltip"
import { useMedia } from "../hooks/use-media"

type Props = {
  item: any
  onUpdate: (item: any) => void
  kind: LikeType
  onShowComments: (item: any) => void
  onShowDonations: (item: any) => void
}

const LikeFooter: FunctionComponent<Props> = ({
  item,
  onUpdate,
  kind,
  onShowComments,
  onShowDonations,
}) => {
  const { itemLikes, channelLikes, user, accountState } = useUser()
  const { isMobileLayout, isTouchDevice } = useMedia()

  const { sendLike } = useLike()

  const isLiked = useMemo(() => {
    return kind == LikeType.Item
      ? itemLikes.includes(item.url)
      : channelLikes.includes(item.id)
  }, [itemLikes, channelLikes, item.id, item.url])
  const popupState = usePopupState({ variant: "popper", popupId: "demoPopper" })

  const likeButton = useRef<HTMLButtonElement>(null)

  const likeItem = useCallback(
    async (item: Item, amount: number) => {
      const msg = {
        kind: kind,
        target: kind == LikeType.Item ? item.url : item.id,
        amount: amount,
        currency: "",
      }

      sendLike(msg, item, null, function () {
        item.amount += amount
        onUpdate(item)
      })
    },
    [user, accountState]
  )

  const like = useAnimatedCallback(
    likeButton,
    "heartBeat",
    () => {
      if (user) likeItem(item, user.like_value)
      else likeItem(item, 0.01)
    },
    [item, accountState, user]
  )

  const likeCurrentItem = useCallback(
    (amount: number) => {
      likeItem(item, amount)
    },
    [item, accountState]
  )

  const initFlagsLayout = () => {
    const showFlags =
      (item.flags && item.flags.length > 0) || item.green_flags ? true : false
    if (showFlags) {
      if (isMobileLayout) {
        if (
          (item.flags && item.flags.length > 4) ||
          (item.green_flags && item.green_flags.length > 4)
        )
          return [true, false]
        else return [true, true]
      } else return [true, true]
    } else return [false, false]
  }

  const [showFlags, inlineFlags] = initFlagsLayout()

  return (
    <div style={{ width: "100%" }}>
      <div className="bottom row">
        <div className="row date">{prettyDate(item.added_date)}</div>

        {inlineFlags && (
          <Flags
            contentType={kind}
            id={item.id}
            flags={item.flags}
            flag_stats={item.flag_stats}
            green_flags={item.green_flags}
            red_flags={item.red_flags}
            yellow_flags={item.yellow_flags}
          />
        )}

        <div className="lowerToolbar">
          <div className="table">
            <div className="table-row">
              <div className="table-cell">
                {onShowDonations && item.owner && item.owner.length > 0 && (
                  <div className="table" onClick={() => onShowDonations(item)}>
                    <div className="table-row">
                      {item.donate != 0 && (
                        <div className="table-cell comment-count">
                          {user ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: user.displayed_currency_symbol,
                              }}
                            />
                          ) : (
                            <>&#36;</>
                          )}{" "}
                          {prettyAmount(item.donate)}
                        </div>
                      )}

                      <HtmlTooltip title="Donate" placement="top">
                        <button className="thumbsUpContainer">
                          <FontAwesomeIcon
                            className="thumbsUp"
                            icon={faCommentDollar}
                            size="lg"
                          />
                        </button>
                      </HtmlTooltip>
                    </div>
                  </div>
                )}
              </div>
              <div className="table-cell">
                {onShowComments && (
                  <div className="table" onClick={() => onShowComments(item)}>
                    <div className="table-row">
                      {item.comments_count != 0 && (
                        <div className="table-cell comment-count">
                          {item.comments_count}
                        </div>
                      )}

                      <HtmlTooltip title="Comment" placement="top">
                        <button className="thumbsUpContainer">
                          <FontAwesomeIcon
                            className="thumbsUp"
                            icon={faComment}
                            size="lg"
                          />
                        </button>
                      </HtmlTooltip>
                    </div>
                  </div>
                )}
              </div>
              {item.amount > 0 && (
                <div className="amount">
                  {user ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: user.displayed_currency_symbol,
                      }}
                    />
                  ) : (
                    <>&#36;</>
                  )}{" "}
                  {prettyAmount(item.amount)}
                </div>
              )}
              {isTouchDevice ? (
                <div className="table-cell" style={{ marginTop: "-3px" }}>
                  <button
                    className={
                      isLiked ? "thumbsUpContainerActive" : "thumbsUpContainer"
                    }
                    onClick={like}
                    style={{
                      marginTop: "-3px",
                    }}
                  >
                    <FontAwesomeIcon
                      className={isLiked ? "thumbsUpActive" : "thumbsUp"}
                      icon={isLiked ? faThumbsUpActive : faThumbsUp}
                      size="lg"
                    />
                  </button>
                </div>
              ) : (
                <div className="table-cell" style={{ marginTop: "-3px" }}>
                  <button
                    className={
                      isLiked ? "thumbsUpContainerActive" : "thumbsUpContainer"
                    }
                    onClick={like}
                    {...bindHover(popupState)}
                    style={{
                      marginTop: "-3px",
                    }}
                  >
                    <FontAwesomeIcon
                      className={isLiked ? "thumbsUpActive" : "thumbsUp"}
                      icon={isLiked ? faThumbsUpActive : faThumbsUp}
                      size="lg"
                    />
                  </button>
                  {user?.account_type === "global" && (
                    <Popper
                      {...bindPopper(popupState)}
                      transition
                      placement="right"
                      style={{ marginBottom: "0px" }}
                    >
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                          <LikeBar
                            close={popupState.close}
                            like={likeCurrentItem}
                          />
                        </Fade>
                      )}
                    </Popper>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <style jsx>{`
          .date {
            font-size: 14px;
            font-family: Roboto;
            color: rgb(129, 129, 129);
          }
          .bottom {
            justify-content: space-between;
            align-items: center;
            width: 100%;
          }

          .row {
            display: flex;
            min-width: 0;
          }
          .row > :global(*:not(:first-child)) {
            margin-left: 1em;
          }

          .table {
            display: table;
          }
          .table-row {
            display: table-row;
          }
          .table-cell {
            display: table-cell;
            vertical-align: middle;
          }
          .comment-bubble {
            font-size: 16px;
            padding-left: 5px;
            padding-right: 10px;
            color: rgb(96, 96, 96);
          }
          .comment-bubble:hover {
            color: blue;
          }
          .comment-count {
            font-size: 14px;
            font-weight: 500;
            font-family: "Roboto";
            color: rgb(129, 129, 129);
            padding-right: 5px;
            min-width: 30px;
            text-align: right;
          }
          .amount {
            display: table-cell;
            vertical-align: middle;
            font-size: 14px;
            font-weight: 500;
            width: 50px;
            text-align: center;
            font-family: "Roboto";
            color: rgb(129, 129, 129);
            padding-right: 5px;
            padding-left: 5px;
            white-space: nowrap;
          }

          .thumbsUpContainer {
            /*transform: rotate(0.1deg);*/
            color: rgb(129, 129, 129);
          }
          .thumbsUpContainer:hover {
            cursor: pointer;
            color: blue;
          }
          .thumbsUpContainerActive {
            /*transform: rotate(1deg);*/
            color: rgb(129, 129, 129);
          }
          .thumbsUpContainerActive:hover {
            color: blue;
          }

          .thumbsUp {
            z-index: 1;
            margin-top: -3px;
            /*color: rgb(96, 96, 96);*/
            transform: rotate(1deg);
            fill: rgb(129, 129, 129);
            color: rgba(0, 0, 0, 0);
          }
          .thumbsUp:hover {
            color: blue;
          }
          .thumbsUpActive {
            z-index: 1;
            margin-top: "-3px";
            color: blue;
          }
          .likeButton {
          }
          .likeButton:hover {
            cursor: pointer;
          }
          button {
            border-radius: 50%;
            height: 2em;
            width: 2em;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            background-color: transparent;
            color: inherit;
            transition: 200ms background-color;
            z-index: 1;
          }
        `}</style>
      </div>
      {showFlags && !inlineFlags && (
        <Flags
          contentType={kind}
          id={item.id}
          flags={item.flags}
          flag_stats={item.flag_stats}
          green_flags={item.green_flags}
          red_flags={item.red_flags}
          yellow_flags={item.yellow_flags}
        />
      )}
    </div>
  )
}

export default LikeFooter
