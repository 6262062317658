import React, { useCallback } from "react"
import { FunctionComponent, useState } from "react"
import TagEditor from "./tag-editor"

type Props = {
  channelid: string | null
  contentid: string | null
  creator: string | null
  initialTags: string[]
  initialBTags: string[]
  mf: number
  mfg?: number
}

const TagModerator: FunctionComponent<Props> = ({
  channelid,
  contentid,
  creator,
  initialTags,
  initialBTags,
}) => {
  const [tags, setTags] = useState(initialTags)
  const [btags, setBTags] = useState(initialBTags)

  //console.log(" ======== " + tags + " - " + initialTags)
  const onModeration = useCallback(newTags => {
    setTags(newTags)
    setBTags([])
  }, [])

  return (
    <div>
      <div className="tags">
        <TagEditor
          channelid={channelid}
          contentid={contentid}
          creator={creator}
          tags={tags}
          onModeration={onModeration}
        />
      </div>

      {btags && btags.length > 0 && (
        <div className="btags">
          <TagEditor
            channelid={channelid}
            contentid={contentid}
            creator={creator}
            tags={btags}
            onModeration={onModeration}
          />
        </div>
      )}

      <style jsx>{`
        .tags {
          padding: 3px;
          /*border: solid 1px white;*/
          border-radius: 5px;
        }
        .btags {
          padding: 3px;
          /*border: solid 1px red;*/
          border-radius: 3px;
          background-color: #ffff0085; /*#00ff0e3d;*/
        }
      `}</style>
    </div>
  )
}

export default TagModerator
