import { forwardRef, DetailedHTMLProps, TextareaHTMLAttributes } from "react"
import { useStyle } from "../../../hooks/use-style"
import { lightTheme, darkTheme } from "../../../services/theme"
import React from "react"

type Props = DetailedHTMLProps<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
>

const Textarea = forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
    const css = useStyle(theme => ({
        backgroundColor:
            theme === "light"
                ? lightTheme.color.background.secondary
                : darkTheme.color.background.primary,
        borderColor:
            theme === "light"
                ? lightTheme.color.border
                : darkTheme.color.border,
        focusColor:
            theme === "light"
                ? lightTheme.color.active
                : darkTheme.color.active,
    }))

    return (
        <div className="row">
            <textarea ref={ref} {...props} />

            <style jsx>{`
                .row {
                    display: flex;
                    align-items: center;
                }
                textarea {
                    background-color: ${css.backgroundColor};
                    border: 1px solid ${css.borderColor};
                    border-radius: 0.3em;
                    color: inherit;
                    padding: 0.5em 1em;
                    transition: 200ms background-color, 200ms border-color,
                        200ms box-shadow;
                    width: 100%;
                    resize: vertical;
                    font: inherit;
                }
                textarea:focus {
                    box-shadow: 0 0 0 1px ${css.focusColor};
                }
            `}</style>
        </div>
    )
})

export default Textarea
