import { FunctionComponent, ReactNode } from "react"
import { Item } from "../../types"
//import { EmbedYoutube } from "../embed/embed-youtube"
import YouTube from "react-youtube"
import { TwitterTweetEmbed } from "react-twitter-embed"
import InstagramEmbed from "react-instagram-embed"
import TelegramEmbed from "react-telegram-embed"
import React from "react"
import {
  shouldTransform as shouldTransformYouTube,
  getYouTubeIFrameSrc,
  /*getHTML as getYouTubeHTML,*/
} from "../../../node_modules/gatsby-remark-embedder/dist/transformers/YouTube"
import {
  shouldTransform as shouldTransformSoundCloud,
  getHTML as getSoundCloudHTML,
} from "../../../node_modules/gatsby-remark-embedder/dist/transformers/SoundCloud"
import {
  shouldTransform as shouldTransformSpotify,
  getHTML as getSpotifyHTML,
} from "../../../node_modules/gatsby-remark-embedder/dist/transformers/Spotify"
import {
  shouldTransform as shouldTransformTwitter,
  getHTML as getTwitterHTML,
} from "../../../node_modules/gatsby-remark-embedder/dist/transformers/Twitter"

import ReactHtmlParser from "react-html-parser"
import SmartlikeBlog from "./smartlike-blog"

export type Props = {
  children: {
    item: Item
    header: ReactNode
    content: ReactNode
    upperToolbar: ReactNode
    mainImage: ReactNode
    tags: ReactNode
    lowerToolbar: ReactNode
    footer?: ReactNode
  }
}

enum EmbedType {
  General = 1,
  YouTube,
  Twitter,
  Instagram,
  Telegram,
  Smartlike,
}

function getContentType(target: string): EmbedType {
  let parts = target.split(`/`)
  //console.log(parts[2])
  if (parts.length > 2) {
    if (
      parts[2].indexOf("youtube.com") == 0 ||
      parts[2].indexOf("www.youtube.com") == 0
    )
      return EmbedType.YouTube
    else if (
      parts[2].indexOf("twitter.com") == 0 ||
      parts[2].indexOf("www.twitter.com") == 0
    )
      return EmbedType.Twitter
    else if (
      parts[2].indexOf("instagram.com") == 0 ||
      parts[2].indexOf("www.instagram.com") == 0
    )
      return EmbedType.Instagram
    else if (parts[2].indexOf("t.me") == 0) return EmbedType.Telegram
    else if (
      parts[2].indexOf("blog.smartlike.org") == 0 ||
      parts[2].indexOf("examples.smartlike.org") == 0
    )
      return EmbedType.Smartlike
  }
  return EmbedType.General
}

function getYoutubeId(str: string) {
  let parts = str.split(`/`)
  let pars = parts.pop()
  if (pars) {
    if (pars.indexOf(`?v=`) > -1) {
      pars = str.split(`?v=`)[1]
      pars = pars.split(`?`)[0]
      pars = pars.split(`&`)[0]
      return pars
    }
  }
  return ""
}

const getYouTubeHTML = (url: string) => {
  const iframeSrc = getYouTubeIFrameSrc(url)

  return `<div style="position: relative;padding-bottom: 56.25%;padding-top: 0;height: 0;overflow: hidden;"><iframe style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;" src="${iframeSrc}" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>`
}

function tryEmbed(url: string) {
  if (shouldTransformYouTube(url)) return getYouTubeHTML(url)
  else if (shouldTransformSoundCloud(url)) return getSoundCloudHTML(url)
  else if (shouldTransformSpotify(url)) return getSpotifyHTML(url)
  else return ""
}

function getTwitterId(str: string) {
  let parts = str.split(`/`)
  return parts.pop()
}

const PanelImage: FunctionComponent<Props> = ({ children }) => {
  const contentType = getContentType(children.item.url)
  const youtubeId =
    contentType == EmbedType.YouTube ? getYoutubeId(children.item.url) : ""
  const twitterId =
    contentType == EmbedType.Twitter ? getTwitterId(children.item.url) : ""

  const embededHTML: string = tryEmbed(children.item.url)
  //console.log(embededHTML)
  /*
    const opts = {
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    }
    '<iframe><script async src="https://telegram.org/js/telegram-widget.js?14" data-telegram-post="otsuka_bld/8970" data-width="100%" ></script></iframe>'

    */
  const telegramFrame =
    '<iframe style="width: 100%;height: fit-content;" src="https://t.me/otsuka_bld/8970?embed=1" onload="this.style.height="300px""></iframe>'

  return (
    <div>
      <div className="panel">
        <div className="padded" style={{ paddingBottom: 0 }}>
          <div className="top row">
            <div className="row expanding header">{children.header}</div>
            <div className="row fixed">{children.upperToolbar}</div>
          </div>
          {embededHTML.length == 0 &&
            contentType != EmbedType.Twitter &&
            contentType != EmbedType.Instagram &&
            contentType != EmbedType.Telegram &&
            contentType != EmbedType.Smartlike && <div>{children.content}</div>}
        </div>

        {embededHTML.length != 0 && (
          <div className="embedded-frame embedded-frame-silent">
            {/*<YouTube videoId={youtubeId} />*/}
            {ReactHtmlParser(embededHTML)}
          </div>
        )}

        {contentType == EmbedType.Twitter && (
          <div className="centerContent embedded-frame embedded-frame-silent">
            <TwitterTweetEmbed tweetId={twitterId} />
          </div>
        )}

        {contentType == EmbedType.Instagram && (
          <div>
            <InstagramEmbed
              url={children.item.url}
              maxWidth={640}
              hideCaption={false}
              containerTagName="div"
              protocol=""
            />
          </div>
        )}

        {contentType == EmbedType.Telegram && (
          <div className="centerContent embedded-frame embedded-frame-silent">
            <TelegramEmbed src={children.item.url} />
          </div>
        )}

        {contentType == EmbedType.Smartlike && (
          <div className="centerContent">
            <SmartlikeBlog
              title={children.item.title}
              description={children.item.description}
              image={children.item.image}
              link={children.item.url}
            />
          </div>
        )}

        {embededHTML.length == 0 && contentType == EmbedType.General && (
          <div style={{ paddingTop: "10px" }}>{children.mainImage}</div>
        )}

        <div>{children.tags}</div>

        <div className="padded">
          {children.footer && <div className="row">{children.footer}</div>}
        </div>

        <style jsx>{`
          .embedded-frame {
          }

          @media (hover: none), (pointer: coarse) {
            .embedded-frame-silent {
              pointer-events: none;
            }
          }

          .panel {
            width: 100%;
          }
          .panel > *:not(:first-child) {
            /*margin-top: 1em;*/
          }
          .header {
            padding-bottom: 10px;
          }
          .padded {
            padding: 20px;
          }
          .row {
            display: flex;
            min-width: 0;
          }
          .row > :global(*:not(:first-child)) {
            margin-left: 1em;
          }
          .column {
            display: flex;
            flex-direction: column;
            min-width: 0;
          }
          .column > :global(*:not(:first-child)) {
            margin-top: 1em;
          }
          .top {
            justify-content: space-between;
            align-items: flex-start;
          }
          .bottom {
            justify-content: space-between;
            align-items: center;
          }
          .expanding {
            flex-shrink: 1;
          }
          .fixed {
            flex-shrink: 0;
          }
          .centerContent {
            text-align: -webkit-center;
            text-align: -moz-center;
          }
        `}</style>
      </div>
      <a
        className="link"
        href={children.item.url}
        target={children.item.url}
      ></a>
    </div>
  )
}

export default PanelImage
