import { FunctionComponent } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faImage } from "@fortawesome/pro-solid-svg-icons"
import { lightTheme, darkTheme } from "../../services/theme"
import { useStyle } from "../../hooks/use-style"
import React from "react"

type Props = {
  image?: string
  label?: string
  ratio: number
}

const Banner: FunctionComponent<Props> = ({ image, label, ratio }) => {
  const css = useStyle(theme => ({
    iconColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.tertiary
        : darkTheme.color.background.tertiary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
  }))

  return (
    <div className="banner">
      <span className="placeholder">
        <FontAwesomeIcon icon={faImage} />
      </span>

      <div className="image">
        <img src={image} alt={label} loading="lazy" />
      </div>

      <style jsx>{`
                .banner {
                    background-color: ${css.backgroundColor};
                    transition: 200ms background-color, 200ms border-color;
                    padding-top: ${100 / ratio}%;
                    position: relative;
                    /*border-radius: 0.4em;*/
                    overflow: hidden;
                    /*border: 1px solid ${css.borderColor};*/
                }
                .image {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;
                    background-image: ${image ? `url("${image}")` : "none"};
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    overflow: hidden;
                }
                .image > img {
                    position: absolute;
                    top: -9999px;
                    left: -9999px;
                    right: -9999px;
                    bottom: -9999px;
                    margin: auto;
                    opacity: 0;
                    min-width: 100%;
                    min-height: 100%;
                }
                .placeholder {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    height: 50%;
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 40px;
                    color: ${css.iconColor};
                    transition: 200ms color;
                }
            `}</style>
    </div>
  )
}

export default Banner
