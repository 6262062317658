import { useCallback, RefObject, DependencyList } from "react"

export const useAnimatedCallback = (
    ref: RefObject<HTMLElement>,
    animationName: string,
    callback: () => void,
    deps: DependencyList
) =>
    useCallback(() => {
        callback()
        ref.current?.classList.add("animated", animationName)
        const animate = () => {
            ref.current?.classList.remove("animated", animationName)
            ref.current?.removeEventListener("animated", animate)
        }
        ref.current?.addEventListener("animationend", animate)
    }, deps)
