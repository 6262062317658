import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react"
import { useUser } from "../hooks/use-user"
/*
import smiley0 from "../images/like/0.png"
import smiley1 from "../images/like/1.png"
import smiley2 from "../images/like/2.png"
import smiley3 from "../images/like/3.png"
import smiley4 from "../images/like/4.png"
import smiley5 from "../images/like/5.png"
import smiley6 from "../images/like/6.png"
import smiley7 from "../images/like/7.png"
import smiley8 from "../images/like/8.png"
import smiley9 from "../images/like/9.png"
import { PopupState } from "material-ui-popup-state/core"

export const smileys = [
  smiley0,
  smiley1,
  smiley2,
  smiley3,
  smiley4,
  smiley5,
  smiley6,
  smiley7,
  smiley8,
  smiley9,
]*/

type Props = {
  close: () => void
  like: (amount: number) => void
}

const LikeBar: FunctionComponent<Props> = props => {
  const [state, setState] = useState(props)
  const [likeStep, setLikeStep] = useState(0.01)
  const [likeAmount, setLikeAmount] = useState(0.01)
  //const [smiley, setSmiley] = useState(smiley0)
  const { user } = useUser()

  useEffect(() => {
    if (user) {
      setLikeStep(user.like_value)
      setLikeAmount(user.like_value)
    }
  }, [])

  const onKeyUp = event => {
    if (event.keyCode === 13) {
      event.preventDefault()
      state.like(likeAmount)
      state.close()
    }
  }

  const onAmountChange = event => {
    event.preventDefault()
    //console.log("onAmountChange" + event.currentTarget.value)
    const amount = parseFloat(event.currentTarget.value)
    if (amount > 0) setLikeAmount(amount)
  }

  const onClose = event => {
    state.close()
  }
  const onLike = useCallback(
    e => {
      if (e.target == document.getElementById("pledge")) return
      state.like(likeAmount)
      state.close()
    },
    [likeAmount]
  )

  const updateBar = event => {
    var amount = likeAmount
    if (event.target.children.length) {
      amount = likeStep * event.target.id
    }
    //console.log("updateBar " + amount)
    for (var s = event.target; s != null; s = s.nextElementSibling) {
      if (s.children.length) s.children[0].style.backgroundColor = "#CFD8DC"
    }

    //let amount = 0
    for (var s = event.target; s != null; s = s.previousElementSibling) {
      if (s.children.length) s.children[0].style.backgroundColor = "#FFD54F"
      //amount += likeStep
    }

    setLikeAmount(amount) //.toFixed(2))

    //var grade = Math.floor((amount * 100) / 3)
    //if (grade >= 0 && grade < smileys.length) setSmiley(smileys[grade])
  }
  let rows = [],
    i = 0,
    len = 29
  while (++i <= len) rows.push(i)

  return (
    <div className="wrapper">
      <div
        id="btLike"
        className="menuItemContainer"
        data-tooltip="Click the bar to send"
        onMouseLeave={onClose}
        onClick={onLike}
      >
        <div className="menuItem">
          <div style={{ display: "inline-flex", marginLeft: "12px" }}>
            <div style={{ display: "inline-flex", width: "100%" }}>
              <div id="1" className="bar" onMouseEnter={updateBar}>
                <div
                  className="inbar"
                  style={{ backgroundColor: "#FFD54F" }}
                ></div>
              </div>
              {rows.map(function (i) {
                return (
                  <div
                    id={i + 1 + ""}
                    key={i}
                    className="bar"
                    onMouseEnter={updateBar}
                  >
                    <div className="inbar"></div>
                  </div>
                )
              })}
            </div>
            <div className="amountContainer">
              <input
                id="pledge"
                type="number"
                step="0.01"
                value={likeAmount}
                onChange={onAmountChange}
                className="amount"
                onKeyUp={onKeyUp}
              />
              <div className="tooltip">click to donate</div>
            </div>
          </div>
        </div>
        {/*
        <div className="smileyContainer">
          <img
            className="panelIcon"
            id="smile"
            style={{ width: "22px" }}
            src={smiley}
          />
        </div>*/}
        <style jsx>
          {`
            .menuItem {
              display: table-cell;
              vertical-align: middle;
              /*padding-left: 10px;*/
              width: 100%;
              margin: auto;
              z-index: 1000;
            }

            .wrapper {
              width: 320px;
              height: 64px;
            }
            .menuItemContainer {
              /*padding-left: 10px;*/
              display: inline-flex;
              width: 320px;
              text-align: left;
              height: 64px;
              /*background-color: #bbb; #f9f9f9;*/
              background-color: #fff;
              box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
                0 6px 20px 0 rgba(0, 0, 0, 0.19);
              border-radius: 5px;
            }

            .menuItemContainer:hover {
              /*background-color: #f9f9f9;*/
              cursor: pointer;
            }

            .smileyContainer {
              width: 45px;
              text-align: center;
              margin: auto;
            }
            .panelIcon {
              opacity: 0.7;
              /*color: #607D8B;*/
              width: 24px;
              height: 24px;
              /*padding-left: 10px;
            padding-right: 10px;*/
            }

            .amountContainer {
              position: relative;
            }
            .amount {
              width: 80px;
              margin-left: 1px;
              outline: none;
              padding: 4px;
              height: 18px;
              border-radius: 3px;
              text-align: right;
              font-size: 14px;
              border: none;
              font-family: Arial;
              position: absolute;
              top: 35%;
              background-color: #cfd8dc;
              opacity: 0.9;
            }
            .bar {
              height: 64px;
              width: 7px;
              padding-left: 1px;
              padding-right: 2px;
              padding-top: 4px;
              z-index: 10;
              display: flex;
              position: relative;
            }
            .inbar {
              height: 18px;
              width: 100%;
              background-color: #cfd8dc;
              border-radius: 3px;
              position: absolute;
              top: 35%;
              width: 4px;
            }
            .tooltip {
              white-space: nowrap;
              font-size: 13px;
              margin-top: 3px;
              margin-left: -100px;
            }
          `}
        </style>
      </div>
    </div>
  )
}

export default LikeBar
