import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react"
import { useStyle } from "../../hooks/use-style"
import { useUser } from "../../hooks/use-user"
import { darkTheme, lightTheme } from "../../services/theme"
import Banner from "./banner"

type Props = {
  title: string
  description: string
  image: string
  link: string
}

const SmartlikeBlog: FunctionComponent<Props> = ({
  title,
  description: initialDescription,
  image,
  link,
}) => {
  const [description, setDescription] = useState(initialDescription)
  const [readMoreText, setReadMoreText] = useState({ __html: "Read more &gt;" })

  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    inputBorderColor:
      theme === "light" ? lightTheme.color.border : "transparent",
    focusColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
    activeButtonBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
  }))

  const onReadMore = useCallback(() => {
    window.open(link, "_blank")
  }, [])
  const html = { __html: description }

  useEffect(() => {
    const start = initialDescription.indexOf("<readmore>")
    if (start != 0) {
      const end = initialDescription.indexOf("</readmore>")
      if (end > start) {
        setDescription(
          initialDescription.slice(0, start) +
            initialDescription.slice(end + 11)
        )
        setReadMoreText({
          __html: initialDescription.substring(start + 10, end),
        })
      }
    }
  }, [initialDescription])

  return (
    <a href={link} target="_blank" style={{ textDecoration: "none" }}>
      <div
        style={{ textAlign: "left", paddingLeft: "20px", paddingRight: "20px" }}
      >
        {image.length != 0 ? (
          <>
            <img
              src={image}
              style={{
                textAlign: "right",
                maxWidth: "200px",
                maxHeight: "100px",
                float: "right",
              }}
            />
            <h1>{title}</h1>
          </>
        ) : (
          <h1>{title}</h1>
        )}

        <div
          style={{ fontFamily: "Roboto" }}
          dangerouslySetInnerHTML={html}
        ></div>

        {/*image.length != 0 ? (
          <div style={{ paddingTop: "10px" }}>
            <a className="image" href={link} target="_blank">
              <Banner image={image} label={title} ratio={16 / 9} />
            </a>
          </div>
        ) : (*/}
        <div className="submitCell">
          <button
            onClick={onReadMore}
            className="submit"
            dangerouslySetInnerHTML={readMoreText}
          ></button>
        </div>

        <style jsx>{`
          h1 {
            font-size: 22px;
            font-weight: 500;
            margin-bottom: 0.5em;
          }

          .submitCell {
            vertical-align: middle;
            text-align: right;
          }
          .submit {
            font-family: "Roboto";
            font-size: 14px;
            padding-left: 10px;
            padding-right: 10px;
            margin-top: 10px;

            color: inherit;
            background-color: transparent;
            border: 1px solid ${css.borderColor};
            padding: 5px;
            transition: 200ms border-color, 200ms background-color, 200ms color;
            z-index: 1;
            border-radius: 0.3em;
            background-color: ${css.activeButtonBackgroundColor};
          }
          .submit:hover {
            box-shadow: 0 0 0 1px ${css.focusColor};
          }
        `}</style>
      </div>
    </a>
  )
}

export default SmartlikeBlog
