import { FunctionComponent, useCallback } from "react"
import { Channel } from "../../types"
import Avatar from "../layout/avatar"
import { Link } from "@reach/router"
import React from "react"
import { useFilters, Filters } from "../../hooks/use-filters"

export type Props = {
  id: string
  icon?: string
  label: string
  name: string
  filterByChannel: (channel: string) => void
  className?: string
}

const ChannelSelector: FunctionComponent<Props> = ({
  id,
  icon,
  label,
  name,
  filterByChannel,
  className,
}) => {
  return (
    <div
      className="selector"
      onClick={() => {
        filterByChannel(id)
      }}
    >
      <Avatar image={icon ?? undefined} label={label} size={30} />

      <strong>{name}</strong>

      <style jsx>{`
        .selector {
          background-color: rgba(105, 105, 105, 0.05);
          /*
            padding: 5px;
            margin: 2px;*/
          padding: 1px;
          padding-left: 5px;
          padding-right: 5px;
          border-radius: 5px;
          font-size: 12px;
          user-select: none;
          min-width: 50px;
          display: flex;
          align-items: center;
          text-decoration: none;
        }
        .selector:hover {
          background-color: rgba(105, 105, 105, 0.2);
          cursor: pointer;
        }

        .link {
        }
        strong {
          margin-left: 0.5em;
          font-size: 14px;
          /*font-weight: 500;*/
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          flex: 1;
          display: block;
        }
      `}</style>
    </div>
  )
}

export default ChannelSelector
