import React, { FunctionComponent, useCallback, useMemo, useRef } from "react"
import DropdownCompactMenu from "../../components/dropdown-compact-menu"
import { useAnimatedCallback } from "../../hooks/use-animated-callback"
import { useFilters } from "../../hooks/use-filters"
import { useStyle } from "../../hooks/use-style"
import { useUser } from "../../hooks/use-user"
import { darkTheme, lightTheme } from "../../services/theme"
import { Item, LikeType, MuteType } from "../../types"
import ChannelSelector from "../channel/selector"
import Banner from "../layout/banner"
import Description from "../layout/description"
import PanelImage from "../layout/panel-image"
import LikeFooter from "../like-footer"
import TagEditor from "../tag-editor"
import TagModerator from "../tag-moderator"
import ModerationFee from "../moderation-fee"

type Props = {
  liked: boolean
  item: Item
  onLike: (item: Item, amount: number) => void
  onUpdate: (updatedItem: Item) => void
  onMute: (id: string, kind: MuteType) => void
  onShowComments: (item: Item) => void
  onShowDonations: (item: Item) => void
}

const ItemPreview: FunctionComponent<Props> = ({
  liked,
  item,
  onLike,
  onUpdate,
  onMute,
  onShowComments,
  onShowDonations,
}) => {
  const { filters, updateFilters } = useFilters()
  const { accountState } = useUser()

  function collectTags(tags: string[]) {
    let chars = 0
    let res: string[] = []
    for (let i = 0; i < tags.length; i++) {
      res.push(tags[i])
      chars += tags[i].length
      if (chars > 60) break
    }
    return res
  }

  const filterByChannel = useCallback(
    async (channel: string) => {
      updateFilters({ channel_ids: [channel] })
    },
    [filters]
  )

  const likeButton = useRef<HTMLButtonElement>(null)
  const like = useAnimatedCallback(
    likeButton,
    "heartBeat",
    () => onLike(item, 1),
    [item]
  )

  const likeCurrentItem = useCallback(
    (amount: number) => {
      onLike(item, amount)
    },
    [item]
  )

  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.secondary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    infoColor:
      theme === "light"
        ? lightTheme.color.text.secondary
        : darkTheme.color.text.secondary,
    tagColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
  }))

  /*
  const target = useMemo(() => {
    process.env.BASE_URL &&
    parse(process.env.BASE_URL).hostname !== parse(item.url).hostname
      ? "_blank"
      : undefined
  }, [item.url])
  */
  const commentUrl = useMemo(() => "/item/" + item.id, [item.url])

  const showTags = collectTags(item.tags)

  return (
    <div>
      <article>
        <PanelImage>
          {{
            item: item,
            header: (
              <header>
                {item.creator?.name?.length != 0 && (
                  <div className="interactable label">
                    <ChannelSelector
                      id={item.creator.id}
                      label={item.creator.title}
                      icon={item.creator.icon}
                      name={item.creator.name}
                      filterByChannel={filterByChannel}
                    />
                  </div>
                )}
                <div className="interactable label">
                  <ChannelSelector
                    id={item.publisher.id}
                    label={item.publisher.title}
                    icon={item.publisher.icon}
                    name={item.publisher.name}
                    filterByChannel={filterByChannel}
                  />
                </div>
              </header>
            ),
            content: (
              <div>
                <a
                  className="image"
                  href={item.url}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <h1>{item.title}</h1>
                  <div className="description">
                    <Description>{item.description}</Description>
                  </div>
                </a>
              </div>
            ),
            upperToolbar: (
              <div className="options">
                <DropdownCompactMenu
                  item={item}
                  channel={null}
                  onUpdate={onUpdate}
                  onMute={onMute}
                />
              </div>
            ),
            mainImage: (
              <div>
                {item.image && (
                  <a className="image" href={item.url} target="_blank">
                    <Banner
                      image={item.image}
                      label={item.title}
                      ratio={16 / 9}
                    />
                  </a>
                )}
              </div>
            ),
            tags: (
              <>
                {item.btags == undefined || filters.mod == 0 ? (
                  <TagEditor
                    contentid={item.id}
                    creator={item.publisher.url}
                    tags={item.tags}
                  />
                ) : (
                  <TagModerator
                    contentid={item.id}
                    creator={item.publisher.url}
                    initialTags={item.tags}
                    initialBTags={item.btags}
                  />
                )}
                {item.mf > 0 && filters.mod != 0 && (
                  <ModerationFee
                    id={item.id}
                    mf={item.mf}
                    mfg={item.mfg}
                    btags={item.btags}
                    flag_stats={item.flag_stats}
                    onMute={onMute}
                  />
                )}
              </>
            ),
            footer: (
              <LikeFooter
                item={item}
                onUpdate={onUpdate}
                kind={LikeType.Item}
                onShowComments={onShowComments}
                onShowDonations={accountState > 0 ? onShowDonations : null}
              />
            ),
          }}
        </PanelImage>
      </article>
      <style jsx>{`
              .pending {
                padding: 3px;
                border: solid 1px red;
                border-radius: 5px;
              }
      
                @media (hover: hover) and (pointer: fine) {
                  .options {
                    visibility: hidden;
                  }
                  article:hover .options {
                    visibility: visible;
                  }
                }
          

                .menuLabel {
                  display: table-cell;
                  vertical-align: middle;
                }
                .section {
                  margin-left: 10px;
                  margin-top: 10px;
                  font-weight: 500;
                }
                .badge {
                  margin-right: 10px;
                  font-size: 18px;
                  color: rgb(96,96,96);
                  width: 25px;
                }
                .menuItem {
                    padding: 5px 20px;
                    display: table;
                }
    
                ul {
                  margin: 0;
                  padding: 0;
                  font-size: 14px;
                  /*color: #747474;*/
                  font-family: "Roboto", sans-serif;
                }
                li {
                  display: flex;
                  /*align-items: center;*/
                }
                li:hover {
                  background-color: ${css.borderColor};
                  cursor: pointer;
                }
      
                .thumbsUpContainer {
                  /*transform: rotate(0.1deg);*/
                  color: rgb(129, 129, 129);
                }
                .thumbsUpContainer:hover {
                  cursor: pointer;
                  color: blue;
                }
                .thumbsUpContainerActive {
                  /*transform: rotate(1deg);*/
                  color: rgb(129, 129, 129);
                }
                .thumbsUpContainerActive:hover {
                  color: blue,
                }

                .thumbsUp {
                  z-index: 1;
                  margin-top: -3px;
                  /*color: rgb(96, 96, 96);*/
                  transform: rotate(1deg);
                  fill: rgb(129, 129, 129);
                  color: rgba(0, 0, 0, 0);

                  
                }
                .thumbsUp:hover {
                  color: blue;
                }
                .thumbsUpActive {
                  z-index: 1;
                  margin-top: "-3px";
                  color: blue,
                }
                .likeButton {
                }
                .likeButton:hover {
                  cursor: pointer;
                }
                article {
                    background-color: ${css.backgroundColor};
                    transition: 200ms background-color, 200ms border-color;
                    position: relative;
                }
                .interactable {
                    position: relative;
                    z-index: 1;
                }
                .label {
                    min-width: 0;
                }
                .image {
                    border-radius: 0;
                }
                header {
                    display: flex;
                    font-size: 0.8em;
                    overflow: hidden;
                }
                header > *:not(:first-child) {
                    margin-left: 1.6em;
                }
                h1,
                .description {
                    margin: 0;
                }
                h1 {
                    font-size: 22px;
                    font-weight: 500;
                    margin-bottom: 0.5em;
                }
                p {
                    margin: 0;
                    font-size: 0.9em;
                    color: ${css.infoColor};
                    transition: 200ms color;
                }
                p:not(:first-child)::before {
                    content: "•";
                    margin-left: -0.5em;
                    padding-right: 0.5em;
                }
                .link {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    font-size: 0;
                }
                .featured-comment {
                    transform: translateY(-2em);
                    margin-bottom: -2em;
                    margin-left: 10%;
                    margin-right: 20%;
                }
                button {
                    border-radius: 50%;
                    height: 2em;
                    width: 2em;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: none;
                    background-color: transparent;
                    color: inherit;
                    transition: 200ms background-color;
                    z-index: 1;
                }
                button:disabled {
                    opacity: 0.5;
                }
                button:not(:disabled):hover {
                    background-color: ${css.backgroundColor};
                }
                .action-icon {
                    height: 100px;
                    width: 100px;
                    color: red;
                }
                .tag {
                  background-color: rgb(105, 105, 105, 0.1);
                  padding:5px;
                  margin-left:2px;
                  margin-right:2px;
                  border-radius: 5px;
                  font-size: 12px;
                  user-select: none;
                }
                .tag:hover {
                  background-color: rgb(105, 105, 105, 0.3);
                  cursor: pointer;
                }
                .actions {
                  display: table-row;
                  font-size: 14px;
                  color: #111111;
                  font-weight: 400;
                }
                .comments_count {
                  vertical-align: middle;
                  display: table-cell;
                  padding-right: 5px;
                }
                .comments {
                  display: table-cell;
                }
                .like {
                  display: table-cell;
                  vertical-align: middle;
                  margin-left: 15px;
                  font-size: 14px;
                }
                .table { 
                  display: table;
               }
               .table-row {display: table-row; }
               .table-cell {display: table-cell;
                vertical-align: middle; }
                .comment-bubble {
                  font-size: 16px;
                  padding-left:5px;
                  padding-right:10px;
                  color: rgb(96, 96, 96);
                }
                .comment-bubble:hover {
                  color: blue;
                }
                .amount {
                  display: table-cell;
                  vertical-align: middle;
                  font-size: 14px;
                  font-weight: 500;
                  width: 50px;
                  text-align: right;
                }
               }
          `}</style>
    </div>
  )
}
export default ItemPreview
