import { faQuestionSquare, faTimes } from "@fortawesome/pro-light-svg-icons"
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Tooltip, withStyles } from "@material-ui/core"
import React, {
  ChangeEvent,
  FormEvent,
  FunctionComponent,
  useCallback,
  useState,
} from "react"
import Switch from "react-switch"
import { showToast } from "../../components/status-notification"
import { useStyle } from "../../hooks/use-style"
import { getCachedSecret, useUser } from "../../hooks/use-user"
import { rpc } from "../../services/node"
import { darkTheme, lightTheme } from "../../services/theme"
import { Channel, Claim } from "../../types"
import Input from "../layout/form/input"
import SubmitButton from "../layout/form/submit-button"

const WideHtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#ffe180",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 600,
    padding: 10,
    fontSize: 12,
    /*border: "1px solid #dadde9",*/
  },
}))(Tooltip)

type Props = {
  channel: Channel
  close: () => void
}

const ClaimChannel: FunctionComponent<Props> = ({ channel, close }) => {
  const { user, setBalance, updateBalance } = useUser()
  const [url, setUrl] = useState("")
  const [formComplete, setFormComplete] = useState(false)
  const [cut, setCut] = useState(0)
  const [controlAuthorSignature, setControlAuthorSignature] = useState(false)

  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    inputBorderColor:
      theme === "light" ? lightTheme.color.border : "transparent",
    focusColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
    switchOutlineColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,

    activeButtonBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
  }))

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault()

      let claim
      if (channel.kind == 1) {
        claim = {
          alias: channel.id,
          proof: channel.id,
          cut: cut,
          /*control_author_signature: controlAuthorSignature,*/
        }
      } else {
        claim = {
          alias: channel.alias,
          proof: url,
          cut: cut,
          /*control_author_signature: controlAuthorSignature,*/
        }
      }

      rpc("claim", JSON.stringify(claim), user, getCachedSecret(), function (
        res
      ) {
        let amount = 1
        let message
        if (res.status == "ok") message = "Claim sent"
        else message = "Claim failed"

        if (user) updateBalance(-amount)
        showToast(faInfoCircle, "Smartlike network", message)
      })
      close()
    },
    [user?.id, channel.id, cut, url]
  )
  /*
  const handleChange = (event: any) => {
    let share = parseFloat(event.currentTarget.value)
    if (share > 100) share = 100
    else if (share < 0) share = 0
    setCut(share)
  }
*/
  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length == 0) {
      setCut(0)
    } else {
      let share = parseFloat(event.currentTarget.value)
      if (share > 100) share = 100
      if (share < 0) share = 0
      setCut(share)
    }
  }, [])

  const parseAlias = (alias: string | null) => {
    if (alias && alias.length) {
      let p = alias.split("#")
      if (p.length > 1) return [p[0], p[1]]
    }
    return ["", ""]
  }

  const [name, publisher] = parseAlias(channel.alias)

  const onUrlChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length) setFormComplete(true)
    else setFormComplete(false)

    setUrl(event.currentTarget.value)
  }, [])

  return (
    <section>
      <div style={{ float: "right", color: "grey" }}>
        <div
          onClick={() => {
            close()
          }}
          className="close"
        >
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </div>
      </div>

      {channel.kind == 1 ? (
        <>
          <h1>Claim ownership of {channel.id}</h1>

          <form onSubmit={onSubmit}>
            <div style={{ marginBottom: "10px", lineHeight: "18px" }}>
              If you own <span className="highlighted">{channel.id}</span>,
              please add the following TXT DNS entry for {channel.id} and wait
              until it becomes active:
            </div>
            <code>Smartlike: {user.id}</code>
            <div style={{ marginTop: "20px", marginBottom: "15px" }}>
              You can take a share of donations made to content published on
              your domain to support hosting and development:
            </div>

            <div style={{ width: "300px" }}>
              <div className="inline">
                <div>Commission, %</div>
                <div className="amountCell">
                  <input
                    id="pledge"
                    type="number"
                    step="0.01"
                    value={cut}
                    onChange={handleChange}
                    className="amount"
                  />
                </div>
              </div>
              {/*
              <div>
                <div className="inline" style={{ marginTop: "10px" }}>
                  <div style={{ display: "inline-flex" }}>
                    <label htmlFor="filter-1" style={{ marginRight: "10px" }}>
                      Control author signatures
                    </label>{" "}
                    <WideHtmlTooltip
                      title={
                        <React.Fragment>
                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: 400,
                            }}
                          >
                            <p>
                              Smartlike creates user aliases based on HTML meta
                              data.
                            </p>
                            <p>
                              If you allow users who post on your domain to
                              connect their accounts to aliases, they will be
                              able to do it by signing one of their posts.
                            </p>
                            <p>
                              If you want to have better control of your users,
                              you might wish to add metadata yourself:
                            </p>
                            <p>
                              <code>
                                &lt;meta name="smartlike-account"
                                content="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
                                /&gt;
                              </code>
                            </p>
                            <p>
                              This may be userful if there's a chance that
                              commentators can place their signatures on the
                              article's page.
                            </p>
                          </div>
                        </React.Fragment>
                      }
                      placement="top"
                    >
                      <div className="action">
                        <FontAwesomeIcon icon={faQuestionSquare} />
                      </div>
                    </WideHtmlTooltip>
                  </div>
                  <Switch
                    id="filter-1"
                    checked={controlAuthorSignature}
                    onChange={() =>
                      setControlAuthorSignature(!controlAuthorSignature)
                    }
                    activeBoxShadow={`0 0 2px 3px ${css.switchOutlineColor}`}
                    onColor={css.focusColor}
                    offColor={css.activeButtonBackgroundColor}
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                </div>
              </div> */}
            </div>

            <footer>
              <SubmitButton>Submit</SubmitButton>
            </footer>
          </form>
        </>
      ) : (
        <>
          <h1>Claim authorship</h1>

          <form onSubmit={onSubmit}>
            {publisher == "youtube.com" ? (
              <>
                <div
                  style={{
                    marginBottom: "10px",
                    lineHeight: "18px",
                    fontSize: "14px",
                  }}
                >
                  If you are <span className="highlighted">{name}</span> on{" "}
                  <span className="highlighted">{publisher}</span>, you can add
                  the following signature to description in the about page of
                  your YouTube account. All donations to{" "}
                  <span className="highlighted">{name}</span> on{" "}
                  <span className="highlighted">{publisher}</span> will be
                  routed to your Smartlike account.
                </div>
                <code style={{ fontSize: "14px" }}>Smartlike: {user.id}</code>
                <div style={{ marginTop: "10px", fontSize: "14px" }}>
                  Example:&nbsp;&nbsp;
                  <a
                    href="https://www.youtube.com/channel/UCKaDIa76UNHQdhuv0hFRLLw/about"
                    target="_blank"
                  >
                    https://www.youtube.com/channel/UCKaDIa76UNHQdhuv0hFRLLw/about
                  </a>
                </div>
              </>
            ) : publisher == "twitter.com" ? (
              <>
                <div
                  style={{
                    marginBottom: "10px",
                    lineHeight: "18px",
                    fontSize: "14px",
                  }}
                >
                  If you are <span className="highlighted">{name}</span> on{" "}
                  <span className="highlighted">{publisher}</span>, you can add
                  the following signature to a tweet and provide its URL below.
                  All donations to <span className="highlighted">{name}</span>{" "}
                  on <span className="highlighted">{publisher}</span> will be
                  routed to your Smartlike account.
                </div>
                <code style={{ fontSize: "14px" }}>Smartlike: {user.id}</code>
                <div style={{ width: "100%", marginTop: "30px" }}>
                  <h2>URL</h2>
                  <Input value={url} onChange={onUrlChange} />
                  <div style={{ marginTop: "10px", fontSize: "14px" }}>
                    Example:&nbsp;&nbsp;
                    <a
                      href="https://twitter.com/thank_u_org/status/1298938385432748032"
                      target="_blank"
                    >
                      https://twitter.com/thank_u_org/status/1298938385432748032
                    </a>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    marginBottom: "10px",
                    lineHeight: "18px",
                    fontSize: "14px",
                  }}
                >
                  If you are <span className="highlighted">{name}</span> on{" "}
                  <span className="highlighted">{publisher}</span>, you can add
                  the following signature to a publication there and provide its
                  URL below. All donations to{" "}
                  <span className="highlighted">{name}</span> on{" "}
                  <span className="highlighted">{publisher}</span> will be
                  routed to your Smartlike account.
                </div>
                <code style={{ fontSize: "14px" }}>Smartlike: {user.id}</code>
                <div style={{ width: "100%", marginTop: "30px" }}>
                  <h2>URL</h2>
                  <Input value={url} onChange={onUrlChange} />
                </div>
              </>
            )}

            <div style={{ marginTop: "30px", fontSize: "12px" }}>
              To protect the network from spam, one cent is charged in donation
              to its authors for the transaction.
            </div>

            <footer>
              <SubmitButton>Submit</SubmitButton>
            </footer>
          </form>
        </>
      )}

      <style jsx>{`
        .inline {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .ttable {
          display: table-row;
        }
        .trow {
          display: table-row;
        }
        .tcell {
          display: table-cell;
        }
        .close:hover {
          cursor: pointer;
        }
        .highlighted {
          font-weight: 400;
          font-size: 14px;
        }

        .amountCell {
          display: table-cell;
          vertical-align: middle;
          padding-left: 10px;
          width: 80px;
        }
        .amount {
          width: 100%;
          text-align: right;
          font-family: "Roboto";
          font-size: 14px;
          background-color: ${css.backgroundColor};
          border: 1px solid ${css.inputBorderColor};
          transition: 200ms background-color, 200ms border-color,
            200ms box-shadow;
          border-radius: 0.4em;
          padding: 5px;
          resize: vertical;
          width: 100%;
          color: inherit;
          /*min-height: 3.3em;*/
          line-height: 18px;
        }
        .amount:focus,
        .amount:hover {
          box-shadow: 0 0 0 1px ${css.focusColor};
        }
        section {
          padding: 1em 1em 0.5em;
        }
        form > *:not(:first-child) {
          margin-top: 1.2em;
        }
        h2 {
          font-size: inherit;
          font-weight: 500;
        }
        h3 {
          font-size: inherit;
          font-weight: lighter;
          margin: 0;
          margin-right: 1em;
        }
        .misc {
          display: flex;
        }
        .misc > * {
          flex: 1;
          display: flex;
          align-items: center;
        }
        .misc > *:not(:first-child) {
          margin-left: 1em;
        }
        footer {
          text-align: right;
        }
      `}</style>
    </section>
  )
}

export default ClaimChannel
